import React from 'react';
import cn from 'classnames';
import { m as motion, LazyMotion, domAnimation } from 'framer-motion';

import interactionHooks from '@util/interactions';

import Button from '@components/Button';

import * as styles from './Terminal.module.css';

const Terminal = ({ commands, actions, trackingScope }) => {
  const characterAnimation = {
    hidden: {
      opacity: 0,
    },
    visible: {
      opacity: 1,
    },
  };

  let runningDelay = 0;
  const CHARACTER_DELAY = 0.075;

  const preparedCommands = commands.map((command, index) => {
    const commandLength = command.command.length;
    const commandTypingDuration = commandLength * CHARACTER_DELAY;

    const preparedCommand = {
      ...command,
      id: `terminal-command-${index}`,
      delay: runningDelay,
      loadingDelay: runningDelay + commandTypingDuration + CHARACTER_DELAY,
      doneDelay: runningDelay + commandTypingDuration + 0.1 + 1.0,
    };

    runningDelay += commandTypingDuration + 1.0 + 1.0;

    return preparedCommand;
  });

  return (
    <LazyMotion features={domAnimation}>
      <motion.figure
        className={cn(styles.root)}
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.95 }}
      >
        {preparedCommands.map((command, index) => (
          <motion.div
            key={command.id}
            className={cn(styles.commandGroup)}
            variants={{
              hidden: {
                opacity: 0,
              },
              visible: {
                opacity: 1,
              },
            }}
            transition={{
              delay: command.delay,
            }}
          >
            <div key={index} className={cn(styles.command)}>
              <span className={cn(styles.prompt)} aria-hidden>
                $
              </span>
              <span className={cn(styles.commandText)}>
                {command.command.split('').map((character, charIndex) => (
                  <motion.span
                    key={charIndex}
                    variants={characterAnimation}
                    transition={{
                      duration: 0,
                      delay: command.delay + charIndex * CHARACTER_DELAY,
                    }}
                  >
                    {character === ' ' ? '\u00a0' : character}
                  </motion.span>
                ))}
              </span>
            </div>
            <div className={cn(styles.result)}>
              <motion.div
                className={cn(styles.loading)}
                variants={{
                  hidden: {
                    opacity: 0,
                  },
                  visible: {
                    opacity: 1,
                  },
                }}
                transition={{
                  delay: command.loadingDelay,
                }}
              >
                <motion.span
                  className={cn(styles.loadingSpinner)}
                  aria-hidden
                  initial={{ rotate: 0 }}
                  animate={{ rotate: 360 }}
                  transition={{
                    repeat: Infinity,
                    duration: 1,
                    ease: 'linear',
                  }}
                >
                  /
                </motion.span>
                {command.loading ?? 'Processing...'}
              </motion.div>

              <motion.div
                className={cn(styles.done)}
                variants={{
                  hidden: {
                    opacity: 0,
                  },
                  visible: {
                    opacity: 1,
                  },
                }}
                transition={{
                  delay: command.doneDelay,
                }}
              >
                <span className={cn(styles.doneIcon)} aria-hidden>
                  ✓
                </span>
                {command.done ?? 'Done'}
              </motion.div>
            </div>
          </motion.div>
        ))}
        {actions && (
          <motion.div className={styles.actions}>
            {actions.map((action, i) => {
              const interaction = action.interaction
                ? interactionHooks[action.interaction]()
                : null;
              return (
                <div key={i} className={styles.action}>
                  <Button
                    size="small"
                    colorScheme="light"
                    withArrow
                    variant={action.type}
                    href={action.href}
                    onClick={interaction}
                    trackingScope={trackingScope ?? 'CallToActionCard'}
                    fullWidth
                  >
                    {action.label}
                  </Button>
                </div>
              );
            })}
          </motion.div>
        )}
      </motion.figure>
    </LazyMotion>
  );
};

export default Terminal;
