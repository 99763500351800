import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

import { useInView } from 'framer-motion';

import styles from './StaggerAnimateIn.module.css';

const StaggerAnimateIn = ({
  renderAsTag = 'div',
  children,
  threshold = 'some',
  className,
  style,
  childrenClassName,
  childrenStyle,
  childrenTag = 'div',
  initialDelay = 0,
  stagger = 0.1,
}) => {
  const ref = useRef(null);
  const inView = useInView(ref, { amount: threshold, once: true });

  const Tag = renderAsTag;
  const ChildTag = childrenTag;

  useEffect(() => {
    if (inView && ref.current) {
      ref.current.classList.add(styles.inView);
    }
  }, [inView]);

  return (
    <Tag ref={ref} className={cn(className, styles.root)} style={style}>
      {React.Children.map(children, (child, index) => {
        if (child === null) return null;
        return (
          <ChildTag
            className={childrenClassName}
            style={{
              ...childrenStyle,
              '--stagger-delay': `${initialDelay + stagger * index}s`,
            }}
          >
            {child}
          </ChildTag>
        );
      })}
    </Tag>
  );
};

StaggerAnimateIn.propTypes = {
  renderAsTag: PropTypes.oneOf(['div', 'section', 'article', 'ol', 'ul']),
  children: PropTypes.node.isRequired,
  threshold: PropTypes.number,
  className: PropTypes.string,
  style: PropTypes.object,
  childrenClassName: PropTypes.string,
  childrenStyle: PropTypes.object,
  childrenTag: PropTypes.oneOf(['div', 'section', 'article', 'li', 'p']),
  initialDelay: PropTypes.number,
  stagger: PropTypes.number,
};

export default StaggerAnimateIn;
